@import "../../common.scss";

.footer_sec1 {
  position: relative;
  background-color: $color-ECECEC;
  color: $color-181818;
  padding: 5em 0 2.5em;
  font-weight: 500;
  .logo_wrapper {
    .footer_logo {
      width: 100%;
      max-width: 15em;
      margin-bottom: 2.5em;
    }
    .description {
      @include textStyles(1.375em, 1.545em, 0);
      color: $color-181818;
      // max-width: 60%;
      .add_link {
        color: inherit;
        &:hover {
          color: #ee4e56;
        }
      }
    }
  }
  .links_wrapper {
    .link_heading {
      @include textStyles(1.75em, 1.214em, 0);
      margin: 0 0 1.429em;
    }
    .description {
      @include textStyles(1.375em, normal, 0);
      color: $color-181818;
      margin-bottom: 2.313em;
      max-width: 85%;
      // display: flex;
      // align-items: flex-start;
      .footer_icon {
        width: 100%;
        object-fit: contain;
        margin: 0.2em 0.5em 0 0;
        max-width: 1.25em;
        position: absolute;
        left: 0;
        top: 0;
      }
      a {
        // display: flex;
        // align-items: flex-start;
        color: $color-181818;
        font-weight: 700;
        &:hover {
          text-decoration: none;
          color: $color-EE4E56;
        }
      }
      .contact_no {
        position: relative;
        padding-left: 1.75em;
        display: inline-block;
      }
    }
    .link_list {
      .link {
        color: $color-181818;
        text-decoration: none;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @include textStyles(1.375em, normal, 0);
          color: $color-181818;
          margin-bottom: 1.364em;
          &:hover {
            color: $color-EE4E56;
          }
          .footer_icon {
            width: 100%;
            object-fit: contain;
            margin: 0 0.5em 0 0;
            max-width: 1.25em;
          }
        }
      }
      .mail_link {
        font-weight: 700;
      }
    }
  }
  .links_wrapper2 {
    padding: 0 0 0 4em;
  }
  .store_wrapper {
    margin: 1.375em 0 0;
    .description {
      @include textStyles(1.375em, 1.545em, 0);
      color: $color-181818;
      margin: 0 0 1em;
    }
    .social_icon {
      width: 100%;
      max-width: 10.785em;
      height: 100%;
      margin: 0 1em 0 0;
      object-fit: contain;
    }
    .playstore_icon {
      max-width: 11.4063em;
    }
  }
}

.footer_sec2 {
  background-color: $color-ECECEC;
  color: $color-181818;
  .copyright_flex {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $color-707070;
    padding: 1.875em 0 5em;
    .copyright {
      display: block;
      @include textStyles(1.25em, 1.7em, 0);
      color: $color-181818;
    }
    .image_disclaimer {
      display: flex;
      flex-direction: column;
    }
    .togglehead_link {
      color: $color-181818;
      text-decoration: none;
      &:hover {
        padding-bottom: 0.05em;
      }
    }
  }
}

@media (max-width: 992px) {
  .footer_sec1 {
    .logo_wrapper {
      margin: 0 0 2.5em;
      .description {
        max-width: 100%;
        font-size: 1.8em;
      }
    }
    .links_wrapper {
      .link_heading {
        font-size: 2.4em;
        font-weight: 700;
        margin: 0 0 0.5em;
      }
      .description {
        max-width: 100%;
        font-size: 1.8em;
      }
      .link_list {
        .link {
          li {
            font-size: 1.8em;
          }
        }
      }
    }
    .links_wrapper2 {
      padding: 0;
    }
    .store_wrapper {
      .description {
        font-size: 1.8em;
      }
      .social_icon {
        max-width: 12.798em;
      }
      .playstore_icon {
        max-width: 13.5366em;
      }
    }
  }

  .footer_sec2 {
    .copyright_flex {
      .image_disclaimer,
      .copyright {
        font-size: 1.4em;
      }
    }
  }
}

@media (max-width: 600px) {
  .footer_sec1 {
    padding: 5em 0 2.8em;
    .logo_wrapper {
      margin: 0 0 6em;
      .footer_logo {
        max-width: 25em;
        margin: 0 0 3.3em;
      }
      .description {
        font-size: 2em;
        max-width: 95%;
      }
      .store_wrapper {
        margin-bottom: 2.313em;
      }
    }
    .links_wrapper {
      margin: 0 0 6em;
      .description {
        font-size: 2em;
        max-width: 100%;
        margin-bottom: 0.9em;
      }
      .link_heading {
        font-size: 2.4em;
        margin: 0 0 1.083em;
      }
      .link_list {
        .link {
          li {
            font-size: 2em;
            margin-bottom: 0.9em;
          }
        }
      }
    }
    .links_wrapper3 {
      margin-bottom: 0;
      // br {
      //   display: none;
      // }
    }
    .store_wrapper {
      margin: 0;
    }
  }
  .footer_sec2 {
    padding-bottom: 7.5vh;
    .copyright_flex {
      flex-wrap: wrap;
      justify-content: center;
      padding: 2em 0 0;
      .copyright {
        font-size: 1.4em;
      }
      .image_disclaimer {
        text-align: center;
        margin: 0 0 1.786em;
      }
    }
  }
}
