@import "../../common.scss";

.home_sec1 {
  background: $color-212529;
  .banner_wrap {
    .video_banner {
      position: relative;
      width: 100%;
      height: 100%;
      .thumbnail_wrapper {
        position: relative;
        .play_icon {
          width: 100%;
          max-width: 5.938em;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .upgrad_video {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        object-fit: fill;
        // cursor: pointer;
      }
    }
    .banner_img {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      object-fit: cover;
    }
  }

  .swiper-pagination {
    text-align: right;
    padding: 0 4em 1.5em 0;
  }

  .swiper-pagination-bullet {
    background: $color-E2E2E2;
    opacity: 1;
    width: 1.25em;
    height: 1.25em;
  }

  .swiper-pagination-bullet-active {
    background: $color-D80024;
  }
}

.home_hd_sec {
  padding: 6.875em 0 3.75em;
}

.home_sec3 {
  padding: 3.75em 0;
  .row-1 {
    padding: 3.75em 0 0;
  }
  .row-1,
  .row-2 {
    display: flex;
    .box {
      position: relative;
      width: 100%;
      max-width: 25%;
      // transition: 0.6s;
      // overflow: hidden;
      .image_title_wrap {
        position: relative;
      }
      .back_img {
        width: 100%;
        max-width: 100%;
      }
      .box_title {
        position: absolute;
        left: 0;
        bottom: 0;
        color: $color-ffffff;
        @include textStyles(2.25em, 1.444em, 0);
        font-family: $MS;
        font-weight: 500;
        padding: 0 0 1.611em 1.611em;
        width: 100%;
        max-width: 100%;
        // transition: 0.8s;
        // opacity: 1;
      }
      .box_content {
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        transition: height 0.3s;
        // opacity: 0;
        .box_description {
          width: 100%;
          height: 0;
          height: 100%;
          overflow: hidden;
          transition: height 0.3s;
          @include textStyles(1.75em, 1.5em, 0);
          background: $color-181818;
          color: $color-ffffff;
          display: flex;
          align-items: center;
          padding: 0 1.25em 0 2.286em;
          // opacity: 0;
          // transform: translateY(100%);
          // transition: 0.6s;
        }
      }
      &:hover {
        .box_content,
        .box_description {
          height: 100%;
          transition: height 0.3s;
        }
      }
      // &:hover {
      //   transform: translateY(-15px);
      //   transition: 0.6s;
      //   .box_title {
      //     transform: translateY(100%);
      //     opacity: 0;
      //     // transition: 0.8s;
      //   }
      //   .box_content {
      //     opacity: 1;
      //   }
      //   .box_description {
      //     transform: translateY(0%);
      //     // height: 100%;
      //     transition-delay: 0.2s;
      //     opacity: 1;
      //   }
      // }
    }
  }
  .row-2 {
    .box {
      max-width: 33.33333333%;
    }
  }
}

.home_sec4 {
  .my_container {
    max-width: 100%;
    padding: 0;
  }
  .accommodation_wrap {
    position: relative;
    .accommodation_img {
      width: 100%;
      max-width: 80%;
    }
    .accommodation_content_wrapper {
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 6.688em;
      margin: auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      user-select: text;
      .accommodation_content {
        background: $color-ffffff;
        width: 100%;
        max-width: 41.25em;
        padding: 2.813em 4.688em 3.125em;
        box-shadow: 0px 4px 6px #00000029;
        user-select: text;
        .acc_title {
          @include textStyles(2em, 1.75em, 0);
          font-family: $RL;
          font-weight: 700;
        }
        .highlights_list {
          padding: 2.125em 0 0;
          .list_item {
            @include textStyles(1.5em, 1.417em, 0);
            display: flex;
            align-items: flex-start;
            margin: 0 0 1.25em;
            .icon {
              width: 100%;
              max-width: fit-content;
              max-width: 1.21em;
              object-fit: contain;
              margin: 0 0.938em 0 0;
            }
          }
        }
        .acc_desc {
          @include textStyles(1.5em, 1.417em, 0);
          font-weight: 500;
        }
        .cta_wrapper {
          display: flex;
          margin: 2.125em 0 0;
          .common_cta {
            margin: 0 1.125em 0 0;
          }
        }
      }
    }
  }
  .arrows_wrapper {
    padding: 0 5em 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .left_arrow,
    .right_arrow {
      width: 100%;
      max-width: 3.313em;
      object-fit: contain;
      background: transparent;
      cursor: pointer;
    }
    .left_arrow {
      margin: 0 1.75em 0 0;
    }
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    display: inline-block;
    max-width: fit-content;
    position: relative;
    text-align: left;
    padding: 0;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 3.125em 0 0 5em;
  }

  .swiper-pagination-bullet {
    background: $color-E2E2E2;
    opacity: 1;
    width: 1.25em;
    height: 1.25em;
  }

  .swiper-pagination-bullet-active {
    background: $color-D80024;
  }
}

.home_sec5 {
  // padding: 3.75em 0;
  padding: 3.75em 0 0;
  .row-1 {
    padding: 3.75em 0 0;
  }
  .row-1,
  .row-2 {
    display: flex;
    .box {
      position: relative;
      width: 100%;
      max-width: 33.33333%;
      // transition: 0.6s;
      // overflow: hidden;
      .back_img {
        width: 100%;
        max-width: 100%;
      }
      .box_title {
        position: absolute;
        left: 0;
        bottom: 0;
        color: $color-ffffff;
        @include textStyles(2.25em, 1.444em, 0);
        font-family: $MS;
        font-weight: 500;
        padding: 0 0 1.611em 1.611em;
        width: 100%;
        max-width: 100%;
        // transition: 0.8s;
        // opacity: 1;
      }
      .box_content {
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        transition: height 0.3s;
        // opacity: 0;
        .box_description {
          width: 100%;
          height: 0;
          height: 100%;
          overflow: hidden;
          transition: height 0.3s;
          @include textStyles(1.75em, 1.5em, 0);
          background: $color-181818;
          color: $color-ffffff;
          display: flex;
          align-items: center;
          padding: 0 1.25em 0 2.286em;
          // opacity: 0;
          // transform: translateY(100%);
          // transition: 0.6s;
        }
      }
      &:hover {
        .box_content,
        .box_description {
          height: 100%;
          transition: height 0.3s;
        }
      }
      // &:hover {
      //   transform: translateY(-15px);
      //   transition: 0.6s;
      //   .box_title {
      //     transform: translateY(100%);
      //     opacity: 0;
      //   }
      //   .box_content {
      //     opacity: 1;
      //   }
      //   .box_description {
      //     transform: translateY(0%);
      //     transition-delay: 0.2s;
      //     opacity: 1;
      //   }
      // }
    }
  }
  .row-2 {
    .box {
      max-width: 50%;
    }
  }
}

.home_sec6 {
  .home_hd_sec {
    padding: 3.75em 0 3.125em;
  }
  .life_video {
    position: relative;
    width: 100%;
    height: 100%;
    .thumbnail_wrapper {
      position: relative;
      height: 100vh;
      .student_life_youtube {
        min-height: 100vh;
      }
      .play_icon {
        width: 100%;
        max-width: 5.938em;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .students_life_video {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      object-fit: fill;
      cursor: pointer;
      aspect-ratio: 16/9;
    }
    .student_thumb {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      object-fit: cover;
    }
  }
}

.home_sec7 {
  padding: 3.75em 0;
  .row-1 {
    padding: 3.75em 0 0;
  }
  .row-1,
  .row-2 {
    display: flex;
    .box {
      position: relative;
      width: 100%;
      max-width: 33.33333%;
      // transition: 0.6s;
      // overflow: hidden;
      .ifram_video {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .row-2 {
    .box {
      max-width: 50%;
    }
  }
}

@media (max-width: 1080px) {
  .state_container {
    .section_description {
      padding-left: 2em;
    }
    .cta_wrapper {
      padding-left: 3.5em;
    }
  }
}

@media (max-width: 992px) {
  .home_hd_sec {
    .section_description {
      padding: 1em 0 0;
    }
  }
  .home_sec3 {
    .row-1,
    .row-2 {
      .box {
        max-width: 50%;
      }
    }
  }
  .home_sec3,
  .home_sec5 {
    .row-1,
    .row-2 {
      flex-wrap: wrap;
      .box {
        // max-width: 50%;
        height: 100%;
        margin: 0 0 3em;
        // transition: initial;
        .image_title_wrap {
          position: relative;
        }
        // .box_title {
        //   opacity: 1;
        //   transition: initial;
        // }
        .box_content {
          position: relative;
          height: 100%;
          top: auto;
          bottom: auto;
          left: auto;
          right: auto;
          border: 1px solid rgba($color: $color-181818, $alpha: 0.1);
          // opacity: 1;
          .box_description {
            background: $color-ffffff;
            color: $color-181818;
            font-size: 2em;
            padding: 1em 1em 1.25em;
            position: relative;
            height: 100%;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
            // transform: translateY(0%);
            // transition: initial;
            // opacity: 1;
          }
        }
        // &:hover {
        //   transform: translateY(0);
        //   transition: initial;
        //   .box_title {
        //     transform: translateY(0%);
        //     opacity: 1;
        //   }
        //   .box_content {
        //     opacity: 1;
        //   }
        //   .box_description {
        //     transform: translateY(0%);
        //     transition-delay: initial;
        //     opacity: 1;
        //   }
        // }
      }
    }
  }
  .home_sec4 {
    .accommodation_wrap {
      .accommodation_content_wrapper {
        right: 3em;
      }
    }
  }
  .home_sec5 {
    .row-2 {
      .box {
        max-width: 50%;
      }
    }
  }
  .state_container {
    .section_description {
      padding-left: 0;
    }
    .cta_wrapper {
      padding-left: 0;
    }
  }

  .home_sec7 {
    .row-1,
    .row-2 {
      flex-wrap: wrap;
      .box {
        // max-width: 50%;
        height: 100%;
        margin: 0 0 3em;
      }
    }
    .row-2 {
      .box {
        max-width: 50%;
      }
    }
  }
}

@media (max-width: 600px) {
  .home_sec1 {
    .scroll_down {
      width: 100%;
      height: 100%;
      max-width: 2.625em;
      max-height: 2.063em;
      object-fit: contain;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 14em;
      margin: 0 auto;
      cursor: pointer;
      z-index: 2;
      &:hover {
        animation: downAnim 1s infinite;
      }
    }
    .banner_wrap {
      .video_banner {
        .upgrad_video {
          min-height: 90vh;
          object-fit: cover;
        }
      }
      .banner_img {
        min-height: 90vh;
      }
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      text-align: left;
      bottom: 5em;
      left: 1.5em;
    }
  }

  .home_hd_sec {
    padding: 3.6em 0 3em 2em;
    .section_description {
      padding: 2em 0 0;
    }
    .cta_wrapper {
      .common_cta {
        margin: 1.58em 0 0;
      }
    }
  }

  // .home_sec3 {
  //   .row-1 {
  //     .box {
  //       .box_title {
  //         justify-content: flex-start;
  //         padding: 0 0 1.611em 1.611em;
  //       }
  //     }
  //   }
  // }

  .home_sec3,
  .home_sec5 {
    padding: 3em 0;
    .section_title {
      padding: 0 0 0 0.5em;
    }
    .vert_horizon_line {
      &::before {
        left: 0.45em;
      }
      &::after {
        right: 95.2%;
      }
    }

    .row-1 {
      padding: 3.6em 0 0;
    }
    .row-1,
    .row-2 {
      flex-wrap: wrap;
      .box {
        height: 100%;
        max-width: 100%;
        margin: 0 0 3em;
        .back_img {
          max-height: 26.5em;
        }
        .box_content {
          .box_description {
            font-size: 1.8em;
            padding: 1em 0.833em;
          }
        }
        .box_title {
          padding: 0 1.611em 1.611em 1.611em;
        }
      }
    }
  }

  .state_container {
    padding: 0 0 3em 2em;
  }

  .home_sec4 {
    .accommodation_wrap {
      .accommodation_img {
        max-width: 100%;
      }
      .accommodation_content_wrapper {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        margin: -5em 1.5em 0;
        .accommodation_content {
          padding: 3.3em 4em 5em 2.7em;
          .acc_title {
            font-size: 2.2em;
          }
          .acc_desc {
            font-size: 1.8em;
            padding: 0.222em 0 0;
          }
          .highlights_list {
            padding: 2.5em 0 0;
            .list_item {
              font-size: 1.8em;
              margin: 0 0 1em;
              .icon {
                max-width: 1.167em;
              }
            }
          }
          .cta_wrapper {
            margin: 2.2em 0 0;
            .common_cta {
              margin: 0 0.5em 0 0;
            }
          }
        }
      }
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .home_sec4 .swiper-pagination-bullets.swiper-pagination-horizontal,
    .home_sec4 .swiper-pagination-custom,
    .home_sec4 .swiper-pagination-fraction {
      padding: 3.5em 0 0 1.5em;
    }
    .arrows_wrapper {
      position: relative;
      padding: 0 1.5em 0 0;
      z-index: 2;
    }
  }

  .home_sec5 {
    // padding: 3em 0 1.2em;
    padding: 3em 0 0;
  }

  .home_sec6 {
    .home_hd_sec {
      padding: 0 0 3.6em;
    }
    .section_title {
      padding: 0 0 0 0.5em;
    }
    .life_video {
      .students_life_video,
      .student_thumb {
        min-height: 70vh;
        object-fit: cover;
      }
    }
  }

  .home_sec7 {
    padding: 3em 0;
    .row-1 {
      padding: 3.6em 0 0;
    }
    .row-1,
    .row-2 {
      flex-wrap: wrap;
      .box {
        height: 100%;
        max-width: 100%;
        margin: 0 0 3em;
        .ifram_video {
          max-height: 26.5em;
        }
      }
    }
  }
}
