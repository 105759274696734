@import "../../common.scss";

.about_sec1 {
  position: relative;
  background: $color-212529;
  .scroll_down {
    width: 100%;
    height: 100%;
    max-width: 2.625em;
    max-height: 2.063em;
    object-fit: contain;
    position: absolute;
    bottom: 5em;
    cursor: pointer;
    z-index: 1;
    &:hover {
      animation: downAnim 1s infinite;
    }
  }
  .banner_wrap {
    .video_banner {
      position: relative;
      width: 100%;
      height: 100%;
      .thumbnail_wrapper {
        position: relative;
        .play_icon {
          width: 100%;
          max-width: 5.938em;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .upgrad_video {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        object-fit: fill;
        cursor: pointer;
      }
    }
    .banner_img {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      object-fit: cover;
    }
  }
}

.about_sec2 {
  max-width: 85%;
  margin: 0 auto;
  .about_hd_sec {
    padding: 6.875em 0 7.5em;
    .section_description {
      padding: 3em 0 0;
    }
  }
}

.about_sec3 {
  overflow: hidden;
}

.about_sec4 {
  padding: 7.5em 0 3.75em;
  .section_64title {
    text-align: center;
  }
  .row-1 {
    padding: 3.75em 0 0;
  }
  .row-1 {
    display: flex;
    .box {
      position: relative;
      width: 100%;
      max-width: 25%;
      .image_title_wrap {
        position: relative;
      }
      .back_img {
        width: 100%;
        max-width: 100%;
      }
      .box_title {
        position: absolute;
        left: 0;
        bottom: 0;
        color: $color-ffffff;
        @include textStyles(2.25em, 1.444em, 0);
        font-family: $MS;
        font-weight: 500;
        padding: 0 0 1.611em 1.611em;
        width: 100%;
        max-width: 100%;
      }
      .box_content {
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        transition: height 0.3s;
        .box_description {
          width: 100%;
          height: 0;
          overflow: hidden;
          transition: height 0.3s;
          @include textStyles(1.75em, 1.5em, 0);
          background: $color-181818;
          color: $color-ffffff;
          display: flex;
          align-items: center;
          padding: 0 1.25em 0 2.286em;
        }
      }
      &:hover {
        .box_content,
        .box_description {
          height: 100%;
          transition: height 0.3s;
        }
      }
    }
  }
}

.about_sec5 {
  padding: 3.75em 0;
}

.about_sec6 {
  padding: 7.5em 0;
  .text_container {
    .section_description {
      padding: 3em 0 0;
    }
    .cta_wrapper {
      padding: 0.813em 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .common_cta {
        margin-right: 1.125em;
        color: #ffffff;
        &:hover {
          color: #181818;
        }
      }
    }
  }

  .text_container1 {
    padding: 0 0 6.625em;
  }
}

@media (max-width: 992px) {
  .about_sec4 {
    .row-1 {
      flex-wrap: wrap;
      .box {
        max-width: 50%;
        height: 100%;
        margin: 0 0 3em;
        .box_content {
          position: relative;
          height: 100%;
          top: auto;
          bottom: auto;
          left: auto;
          right: auto;
          border: 1px solid rgba($color: $color-181818, $alpha: 0.1);
          .box_description {
            background: $color-ffffff;
            color: $color-181818;
            font-size: 2em;
            padding: 1em 1em 1.25em;
            position: relative;
            height: 100%;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .about_sec1 {
    .scroll_down {
      max-width: 2.8em;
      max-height: 2.8em;
      bottom: 7.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
      animation: downAnim 1s infinite;
    }
    .banner_wrap {
      .video_banner {
        .upgrad_video {
          min-height: 90vh;
          object-fit: cover;
        }
      }
      .banner_img {
        min-height: 90vh;
      }
    }
  }

  .about_sec2 {
    max-width: 100%;
    .about_hd_sec {
      padding: 3.6em 0 6em 2em;
      .section_description {
        padding: 2em 0 0;
      }
    }
  }

  .about_sec3 {
    padding: 0 0 3em;
  }

  .about_sec4 {
    padding: 3em 0 0;
    .section_64title {
      text-align: left;
    }
    .row-1 {
      padding: 2.2em 0 0;
      flex-wrap: wrap;
      .box {
        height: 100%;
        max-width: 100%;
        margin: 0 0 3em;
        .back_img {
          max-height: 26.5em;
        }
        .box_content {
          .box_description {
            font-size: 1.8em;
            padding: 1em 0.833em;
          }
        }
        .box_title {
          padding: 0 1.611em 1.611em 1.611em;
        }
      }
    }
  }

  .about_sec5 {
    padding: 3em 0 2.2em;
  }

  .about_sec6 {
    padding: 6em 0 6em 2em;
    .text_container1 {
      padding: 0 0 6em;
    }
    .text_container {
      .section_description {
        padding: 2em 0 0;
      }
    }
    .cta_wrapper {
      .common_cta {
        margin-right: 0.5em;
      }
    }
  }
}
