@import "../../common.scss";

.refund_sec1 {
  padding: 5em 0 0;
  .text_container {
    margin-bottom: 4em;
    .heading {
      @include textStyles(4em, 1.3125, 0);
      color: $color-333333;
      font-weight: 400;
      font-family: "Raleway";
      max-width: 100%;
      text-align: left;
    }
    .description {
      @include textStyles(1.5em, normal, 0);
      color: $color-333333;
      font-family: "Poppins", sans-serif;
      max-width: 100%;
      margin-top: 1em;
      text-align: left;
    }
    .main_head {
      text-align: center;
    }
  }
}

@media (max-width: 600px) {
  .refund_sec1 {
    padding: 5em 0;
    .text_container {
      .heading {
        font-size: 4em;
        line-height: 1.3;
      }
      .description {
        font-size: 1.4em;
        line-height: 1.667;
        margin-top: 1.223em;
      }
    }
  }
}
