@import "../../common.scss";

.gallery_sec1 {
  padding: 6.875em 0 0;
}

.gallery_sec2 {
  padding: 4.4375em 5em 7.75em;
  .gallery_row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: 31.0625em 100%;
    // grid-template-rows: 31.0625em 34.9375em;
    gap: 2.5em;
    margin-top: 2.5em;
    .gallery_column {
      max-width: 100%;
      //   padding: 0 4px;
      //   height: 31.0625em;
      &:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
        height: 31.0625em;
      }
      &:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        height: 31.0625em;
      }
      &:nth-child(3) {
        grid-column: 3;
        grid-row: 1 / span 2;
        height: 100%;
      }
      &:nth-child(4) {
        grid-column: 1 / span 2;
        grid-row: 2;
        height: 31.0625em;
      }
    }
    .gallery_three_column {
      &:nth-child(3) {
        grid-column: 3;
        grid-row: 1 / span 1;
        height: 31.0625em;
      }
    }
    .gallery_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      // height: 31.0625em;
    }
  }

  .btn_wrapper {
    text-align: center;
    margin-top: 4.5625em;
    .button_wrapper {
      border: none;
      border-bottom: 1px solid #d80024;
      background-color: transparent;
      color: #d80024;
      font-size: 1.5em;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 1.208;
      cursor: pointer;
    }
  }
}

@media (max-width: 991px) {
  .gallery_sec2 {
    .gallery_row {
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5em;
      .gallery_column {
        max-width: 100%;
        //   padding: 0 4px;
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
        }
        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }
        &:nth-child(3) {
          grid-column: 1 / span 2;
          grid-row: 2;
          height: 31.0625em;
        }
        &:nth-child(4) {
          grid-column: 1 / span 2;
          grid-row: 3;
        }
      }
      .gallery_img {
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .gallery_sec1 {
    padding: 3.957em 0 0 2.05em;
  }
  .gallery_sec2 {
    padding: 2.8em 1.6em 6.356em;
    .gallery_row {
      grid-template-columns: repeat(2, 1fr);
      //   grid-template-rows: 21.488em 16.75em;
      gap: 0.8em;
      margin-top: 0.8em;
      .gallery_column {
        max-width: 100%;
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
          height: 21.488em;
        }
        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
          height: 21.488em;
        }
        &:nth-child(3) {
          grid-column: 1 / span 2;
          grid-row: 2;
          height: 21.488em;
        }
        &:nth-child(4) {
          grid-column: 1 / span 2;
          grid-row: 3;
          height: 21.488em;
        }
      }
      .gallery_img {
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
    .btn_wrapper {
      margin-top: 2.8em;
      .button_wrapper {
        font-size: 1.6em;
        line-height: 1.1875;
      }
    }
  }
}
