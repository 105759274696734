.explore_sec1 {
  padding: 6.875em 0 0;
}

.explore_sec2 {
  padding: 0 0 7.5em;
  .explore_row {
    padding: 7.5em 0 0;
    margin: 0 auto;
  }
  .image_box_1 {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    .box1_img {
      width: 100%;
      max-width: 50%;
      height: 100%;
      display: block;
      &:nth-child(1) {
        padding-right: 0.75em;
      }
      &:nth-child(2) {
        padding-left: 0.75em;
      }
    }
  }
  .image_box_2 {
    width: 100%;
    max-width: 100%;
    height: 100%;
    .box2_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }

  .content_box {
    padding: 0 8.0625em 0 6.1875em;
    width: 100%;
    max-width: 100%;
    height: 100%;
    .content_title {
      font-size: 4em;
      line-height: 1.313em;
      letter-spacing: 0;
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      margin: 0 0 0.9375em;
      &.margin_zero {
        margin: 0;
      }
    }
    .content_title_univ {
      margin: 0;
    }
    .connectivity_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1.78125em;
      &:last-child {
        margin-bottom: 0;
        .details_box {
          border-bottom: none;
        }
      }
    }
    .details_box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #1818184d;
      padding-bottom: 1.0178em;
      //   padding: 0 3.6875em 1.0178em 0;
      width: 100%;
      max-width: 73.422%;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .details_icon {
        width: 100%;
        max-width: 1.875em;
        object-fit: contain;
      }
      .heading {
        color: #181818;
        font-size: 1.75em;
        font-weight: 400;
        letter-spacing: 0px;
        // line-height: 2.625;
        line-height: normal;
        margin: 0 0 0 0.5em;
      }
    }
    .distance_wrapper {
      padding-left: 3.6875em;
      width: 100%;
      max-width: 26.578%;
      .distance {
        color: #181818;
        font-size: 1.75em;
        font-weight: bold;
        letter-spacing: 0px;
        //   line-height: 2.625;
        line-height: normal;
      }
    }
  }

  .content_box_1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left_box {
    padding: 0 9.25em 0 5em;
  }
}

@media (max-width: 991px) {
  .explore_sec2 {
    .content_box {
      padding: 4.6em 8.0625em 0 6.1875em;
    }
  }
}

@media (max-width: 767px) {
  .explore_sec1 {
    padding: 3.957em 0.85em 0 3.55em;
    .my_container {
      padding: 0;
    }
  }

  .explore_sec2 {
    padding: 0 0 5.7em;
    .explore_row {
      padding: 6em 0 0;
    }
    .image_box_1 {
      .box1_img {
        &:nth-child(1) {
          padding-right: 0.45em;
        }
        &:nth-child(2) {
          padding-left: 0.45em;
        }
      }
    }
    .content_box {
      padding: 3em 1.6em 0;
      .content_title {
        font-size: 4em;
        line-height: 1.3;
        margin: 0 0 0.75em;
        .bold_text {
          display: block;
        }
      }
      .content_title_univ {
        font-size: 3em;
        margin: 0;
      }
      .connectivity_wrapper {
        padding-bottom: 1.95em;
        border-bottom: 1px solid #1818184d;
        margin-bottom: 1.85em;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
      .details_box {
        border-bottom: none;
        max-width: 61.775%;
        padding-bottom: 0;
        .details_icon {
          max-width: 1.8em;
        }
        .heading {
          font-size: 1.8em;
          line-height: 1.667;
          margin: 0 0 0 0.7223em;
        }
      }
      .distance_wrapper {
        max-width: 38.225%;
        align-self: flex-end;
        .distance {
          font-size: 2em;
          line-height: 1.5;
        }
      }
    }
  }
}
