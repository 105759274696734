@import "../../common.scss";

.breadcrumb_wrapper {
  width: 100%;
  max-width: fit-content;
  position: absolute;
  top: 8em;
  z-index: 5;
  display: flex;
  .blink_1 {
    color: $color-ffffff;
    @include textStyles(1.125em, 2.333em, 0);
    font-family: $MS;
    font-weight: 300;
    text-decoration: none;
    display: inline;
    .home_icon {
      width: 100%;
      max-width: 1.5em;
      object-fit: contain;
    }
  }
  .noclick {
    pointer-events: none;
  }
}

@media (max-width: 600px) {
  .breadcrumb_wrapper {
    .blink_1 {
      font-size: 1.4em;
    }
  }
}
