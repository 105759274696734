@import "../../common.scss";

.combanner_sec1 {
  position: relative;
  // padding: 7.9375em 0 0;
  height: 100%;
  min-height: 36em;
  .img_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 36em;
    .combanner_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
    }
  }
  .breadcrumb_wrapper {
    width: 100%;
    max-width: fit-content;
    position: absolute;
    top: 8em;
    z-index: 5;
    display: flex;
    .blink_1 {
      color: $color-ffffff;
      @include textStyles(1.125em, 2.333, 0);
      font-family: $MS;
      font-weight: 400;
      text-decoration: none;
      display: inline;
      .home_icon {
        width: 100%;
        max-width: 1.5em;
        object-fit: contain;
      }
    }
    .noclick {
      pointer-events: none;
    }
  }
  .title_wrapper {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-self: center;
    align-items: center;
    text-align: center;
    .title {
      font-family: "Raleway";
      font-size: 4em;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.3125;
      color: #ffffff;
      width: 100%;
      max-width: 100%;
      br {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .combanner_sec1 {
    min-height: 37.1em;
    .img_wrapper {
      min-height: 37.1em;
    }
    .breadcrumb_wrapper {
      .blink_1 {
        font-size: 1.4em;
        .home_icon {
          max-width: 1.433em;
        }
      }
    }
    .title_wrapper {
      .title {
        font-size: 3.4em;
        line-height: 1.1667;
        br {
          display: block;
        }
      }
    }
  }
}
