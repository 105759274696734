@import "../../common.scss";

.amenities_sec1 {
  position: relative;
  background: $color-212529;
  .scroll_down {
    width: 100%;
    height: 100%;
    max-width: 2.625em;
    max-height: 2.063em;
    object-fit: contain;
    position: absolute;
    bottom: 5em;
    cursor: pointer;
    z-index: 1;
    &:hover {
      animation: downAnim 1s infinite;
    }
  }
  .banner_wrap {
    .video_banner {
      position: relative;
      width: 100%;
      height: 100%;
      .thumbnail_wrapper {
        position: relative;
        .play_icon {
          width: 100%;
          max-width: 5.938em;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .upgrad_video {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        object-fit: cover;
        // cursor: pointer;
      }
    }
    .banner_img {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      object-fit: cover;
    }
  }
}

.amenities_sec2 {
  .amenities_hd_sec {
    padding: 6.875em 0 7.5em;
    .section_description {
      padding: 3em 0 0;
    }
  }
}

.amenities_sec3 {
  .custom_space {
    max-width: 95%;
  }
  .card_grid {
    display: flex;
    justify-content: space-between;
    // column-gap: 1.5em;
    .column {
      width: 100%;
      max-width: 32.5%;
    }
  }
}

.amenities_sec4 {
  padding: 3.75em 0 7.5em;
}

@media (max-width: 992px) {
  .amenities_sec2 {
    .amenities_hd_sec {
      padding: 5em 0;
      .section_description {
        padding: 1.5em 0 0;
      }
    }
  }
  .amenities_sec4 {
    padding: 2em 0 5em;
  }
}

@media (max-width: 600px) {
  .amenities_sec1 {
    .scroll_down {
      max-width: 2.8em;
      max-height: 2.8em;
      bottom: 7.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
      animation: downAnim 1s infinite;
    }
    .banner_wrap {
      .video_banner {
        .upgrad_video {
          min-height: 90vh;
          object-fit: cover;
        }
      }
      .banner_img {
        min-height: 90vh;
      }
    }
  }

  .amenities_sec2 {
    .amenities_hd_sec {
      padding: 3.5em 0 6em 2em;
    }
  }

  .amenities_sec3 {
    .custom_space {
      max-width: 100%;
    }
    .card_grid {
      display: flex;
      flex-direction: column;
      .column {
        display: flex;
        flex-direction: column;
      }
      .am_card {
        .cta_wrapper {
          display: none;
        }
      }
    }
  }

  .amenities_sec4 {
    padding: 3em 0 6em 2em;
  }
}
