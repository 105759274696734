@import "../../common.scss";

.sl_sec1 {
  position: relative;
  background: $color-212529;
  .scroll_down {
    width: 100%;
    height: 100%;
    max-width: 2.625em;
    max-height: 2.063em;
    object-fit: contain;
    position: absolute;
    bottom: 5em;
    cursor: pointer;
    z-index: 1;
    &:hover {
      animation: downAnim 1s infinite;
    }
  }
  .banner_wrap {
    .video_banner {
      position: relative;
      width: 100%;
      height: 100%;
      .thumbnail_wrapper {
        position: relative;
        .play_icon {
          width: 100%;
          max-width: 5.938em;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .upgrad_video {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .banner_img {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      object-fit: cover;
    }
  }
}

.sl_sec2 {
  .sl_hd_sec {
    padding: 6.875em 0 7.5em;
    .section_description {
      padding: 3em 0 0;
    }
  }
}

.sl_sec3 {
  .custom_space {
    max-width: 100%;
  }
  .box_layout {
    .box {
      position: relative;
      width: 100%;
      max-width: 100%;
      .connecting_border {
        position: absolute;
      }
      .image_title_wrap {
        position: relative;
      }
      .back_img {
        width: 100%;
        max-width: 100%;
      }
      .box_title {
        position: absolute;
        left: 0;
        bottom: 0;
        color: $color-ffffff;
        @include textStyles(2.25em, 1.444em, 0);
        font-family: $MS;
        font-weight: 500;
        padding: 0 0 1.611em 1.611em;
      }
      .box_content {
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        transition: height 0.3s;
        .box_description {
          width: 100%;
          height: 0;
          overflow: hidden;
          transition: height 0.3s;
          @include textStyles(1.75em, 1.5em, 0);
          background: $color-181818;
          color: $color-ffffff;
          display: flex;
          align-items: center;
          padding: 0 1.25em 0 2.286em;
        }
      }
      &:hover {
        .box_content,
        .box_description {
          height: 100%;
          transition: height 0.3s;
        }
      }
    }
  }
  .box_layout01 {
    display: flex;
    align-items: flex-end;
    .column1 {
      width: 100%;
      max-width: 66.666666%;
      padding: 0 2.938em 0 0;
      .container-1 {
        display: flex;
        justify-content: space-between;
        column-gap: 5.875em;
        .box {
          max-width: 50%;
        }
        .box-01 {
          .connecting_border {
            top: 12em;
            right: -10em;
          }
        }

        .box-02 {
          .connecting_border {
            top: 12em;
            right: -15em;
          }
        }
      }
      .container-2 {
        padding: 5.875em 0 0 3.375em;
        .box-04 {
          .connecting_border {
            bottom: -2em;
            left: 10em;
            transform: rotate(90deg);
          }
        }
      }
    }
    .column2 {
      width: 100%;
      max-width: 33.333333%;
      padding: 0 0 0 2.938em;
      .box-03 {
        .connecting_border {
          bottom: 20em;
          left: -5em;
        }
      }
    }
  }

  .box_layout02 {
    display: flex;
    padding: 5.875em 0 0;
    .column1 {
      width: 100%;
      max-width: 35%;
      padding: 0 2.938em 0 0;
      .box-05 {
        height: 100%;
        .connecting_border {
          top: 12em;
          right: -15em;
        }
        .image_title_wrap {
          height: 100%;
        }
        .back_img {
          height: 100%;
        }
      }
    }
    .column2 {
      width: 100%;
      max-width: 65%;
      padding: 0 0 0 2.938em;
      .box-06 {
        .connecting_border {
          bottom: -2em;
          right: 15em;
          transform: rotate(90deg);
        }
      }
    }
  }

  .box_layout03 {
    display: flex;
    padding: 5.875em 0 0;
    .column1 {
      width: 100%;
      max-width: 60%;
      padding: 0 2.938em 0 0;
      .container-1 {
        .box-07 {
          .connecting_border {
            bottom: -2em;
            right: 0;
            left: 0;
            margin: 0 auto;
            transform: rotate(90deg);
          }
        }
      }
      .container-2 {
        padding: 5.875em 0 0;
        .box-09 {
          .connecting_border {
            bottom: -2em;
            left: 10em;
            transform: rotate(90deg);
          }
        }
      }
    }
    .column2 {
      width: 100%;
      max-width: 40%;
      padding: 0 3.375em 0 2.938em;
      .box-08 {
        .connecting_border {
          top: 12.5em;
          left: -5em;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .sl_sec2 {
    .sl_hd_sec {
      padding: 5em 0;
      .section_description {
        padding: 1.5em 0 0;
      }
    }
  }

  .sl_sec3 {
    padding: 0 0 3em;
    .custom_space {
      padding: 0;
    }
    .box_layout {
      flex-direction: column;
      padding: 0;
      .box {
        height: 100%;
        margin: 0 0 3em;
        .connecting_border {
          display: none;
        }
        .back_img {
          aspect-ratio: 4/3;
        }
        .box_content {
          position: relative;
          height: 100%;
          top: auto;
          bottom: auto;
          left: auto;
          right: auto;
          border: 1px solid rgba($color: $color-181818, $alpha: 0.1);
          .box_description {
            background: $color-ffffff;
            color: $color-181818;
            font-size: 2em;
            padding: 1em 1em 1.25em;
            position: relative;
            height: 100%;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }
        }
      }
      .column {
        max-width: 100%;
        padding: 0;
        .container-1,
        .container-2 {
          display: block;
          padding: 0;
          .box {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .sl_sec1 {
    .scroll_down {
      max-width: 2.8em;
      max-height: 2.8em;
      bottom: 7.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
      animation: downAnim 1s infinite;
    }
    .banner_wrap {
      .video_banner {
        .upgrad_video {
          min-height: 90vh;
          object-fit: cover;
        }
      }
      .banner_img {
        min-height: 90vh;
      }
    }
  }

  .sl_sec2 {
    .sl_hd_sec {
      padding: 3.5em 0 6em 2em;
    }
  }

  .sl_sec3 {
    .box_layout {
      .box {
        height: 100%;
        max-width: 100%;
        margin: 0 0 3em;
        .back_img {
          max-height: 26.5em;
        }
        .box_title {
          padding: 0 0.833em 1.25em;
          font-size: 1.8em;
        }
        .box_content {
          .box_description {
            font-size: 1.8em;
            padding: 1em 0.833em;
          }
        }
      }
    }
  }
}
