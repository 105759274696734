@import "../../common.scss";

.box_layout_01 {
  overflow: hidden;
  .box_img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
  .box_text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 3.75em 12.5em 3.75em 6.25em;
    .box_title {
      @include textStyles(4em, 1.313em, 0);
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      margin: 0 0 0.469em;
    }

    .box_description {
      padding: 0;
    }

    .cta_wrapper {
      display: flex;
      align-items: center;
      .common_cta {
        margin-right: 1.125em;
      }
    }
  }
}

@media (max-width: 600px) {
  .box_layout_01 {
    .box_text {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      padding: 2.2em 3em 0;
      .box_title {
        @include textStyles(4em, 1.313em, 0);
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        margin: 0;
        padding: 0 0 0.55em;
      }

      .box_description {
        padding: 0;
      }
      .cta_wrapper {
        .common_cta {
          margin-right: 0.5em;
        }
      }
    }
  }
}
