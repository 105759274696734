@import "../../common.scss";

.contact_sec1 {
  padding: 0 0 8.875em;
  .boxes {
    padding: 5em 5em 0;
  }
  .box-padding {
    &:nth-child(2) {
      padding-left: 3em;
    }
    &:nth-child(3) {
      padding-left: 6em;
    }
  }
  .contact_box {
    max-width: 100%;
    .icon_img {
      width: 2.875em;
      height: 2.875em;
      object-fit: contain;
    }
    .heading {
      @include textStyles(1.875em, normal, 0);
      color: $color-131313;
      text-align: left;
      font-weight: 800;
      max-width: 100%;
      margin: 1em 0;
    }
    .description {
      @include textStyles(1.5em, normal, 0);
      color: $color-212529;
      font-family: "Poppins", sans-serif;
      text-align: left;
      max-width: 100%;
      display: block;
      a {
        color: inherit;
      }
      .numb_title {
        display: block;
      }
      .contact_no {
        font-weight: 700;
      }
    }
    .mail_link {
      font-weight: 700;
    }
  }
}

.contact_sec2 {
  margin-bottom: 10.313em;
  .map_frame {
    width: 100%;
    max-width: 100%;
    max-height: 41.063em;
    padding-right: 3em;
  }
  .contact_form {
    .heading {
      @include textStyles(3.75em, normal, 0);
      color: $color-131313;
      text-align: left;
      font-weight: 800;
      max-width: 100%;
      margin: 0 0 0.5em;
    }
    .form_field_wrapper {
      padding-bottom: 2.813em;
      .form_input {
        @include textStyles(1.25em, normal, 0);
        font-family: "Poppins", sans-serif;
        font-weight: normal;
        color: $color-212529;
        border-radius: 0;
        &::-webkit-input-placeholder {
          /* Edge */
          color: $color-A4A4A4;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $color-A4A4A4;
        }
        &::placeholder {
          color: $color-A4A4A4;
        }
        &:focus {
          border-bottom: 1px solid $color-EE4E56;
          outline: none;
        }
      }
    }
    .cta_wrapper {
      width: 100%;
      max-width: 16.438em;
      .common_cta {
        width: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

@media (max-width: 800px) {
  .contact_sec1 {
    .box-padding {
      padding-left: 0;
      &:nth-child(2),
      &:nth-child(3) {
        padding-left: 0;
      }
    }
    .contact_box {
      margin-bottom: 5em;
    }
    .contact_box3 {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 600px) {
  .contact_sec1 {
    padding: 3em 0;
    .boxes {
      padding: 0;
    }
    .box-padding {
      padding-left: 15px;
      &:nth-child(2) {
        padding-left: 15px;
      }
      &:nth-child(3) {
        padding-left: 15px;
      }
    }
    .contact_box {
      margin: 0 0 3em;
      .heading {
        margin: 0.5em 0 0;
      }
    }
  }
  .contact_sec2 {
    .map_frame {
      padding: 0;
    }
    .contact_form {
      padding: 4em 0 0;
    }
  }
}
