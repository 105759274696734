@import "../../common.scss";

.header_sec.active {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  -webkit-box-shadow: 0px 20px 46px $color-00000029;
  -moz-box-shadow: 0px 20px 46px $color-00000029;
  box-shadow: 0px 20px 46px $color-00000029;
  ul {
    .nav_tab {
      color: $color-212529;
      a {
        color: $color-212529;
      }
    }
  }

  .navlinks ul .nav_tab a {
    color: $color-181818;
    border: 1px solid $color-181818;
  }
  .navlinks ul .nav_tab .book_now_cta {
    border: 1px solid $color-D80024;
    color: $color-ffffff;
    &:hover {
      border: 1px solid $color-181818;
      color: $color-181818;
    }
  }
  .navlinks ul .nav_tab .virtual_cta {
    &:hover {
      border: 1px solid $color-181818;
      color: $color-181818;
    }
  }
}

.down_arrow {
  max-width: 0.55em;
  padding-bottom: 0.25em;
}

.header_sec {
  background: transparent;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  padding: 2.375em 0;
  z-index: 9;
  .navlinks {
    position: relative;
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 50%;
      float: right;
      .logo_wrapper {
        position: absolute;
        top: 0em;
        bottom: 0;
        margin: auto;
        left: 0;
        .logo_img {
          position: relative;
          max-width: 23.292em;
        }
      }
      .nav_tab {
        @include textStyles(1em, 1.5em, 0);
        position: relative;
        color: $color-ffffff;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        margin: 0 0 0 1.125em;
        a {
          display: block;
          color: $color-ffffff;
          position: relative;
          border: 1px solid $color-ffffff;
          border-radius: 5em;
          margin: 0;
          padding: 0.875em 1.375em;
          &:hover {
            text-decoration: none;
            border: 1px solid $color-D80024;
          }
        }
        .book_now_cta {
          border: 1px solid $color-D80024;
          &:hover {
            border: 1px solid $color-ffffff;
          }
        }
        .virtual_cta {
          background-color: $color-ffffff;
          border: 1px solid #181818;
          color: #181818;
          &:hover {
            border: 1px solid $color-ffffff;
            background-color: transparent;
            color: $color-ffffff;
          }
        }
      }

      // .nav_tab.active {
      //   a {
      //     color: $color-A5A5A5;
      //     font-weight: 700;
      //   }
      // }
      .book_your_space {
        text-decoration: none;
        font-family: $MS;
        position: relative;
        text-transform: capitalize;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        margin: 0 0 0 3.8em;
        min-width: 15em;
        .flex_for_ticker {
          @include textStyles(1.5em, 1.5em, 0);
          color: $color-ffffff;
          border-radius: 10px;
          // background: transparent
          //   linear-gradient(90deg, #d4145a 0%, #fbb03b 100%) 0% 0% no-repeat
          //   padding-box;
          background: transparent
            linear-gradient(90deg, #b20710 0%, #ee2c3c 100%) 0% 0% no-repeat
            padding-box;
          padding: 0.75em 1.25em 0.75em 0.75em;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &:hover {
            text-decoration: none;
            .arrow_icon {
              right: 0.5em;
            }
          }
          .arrow_icon {
            @include arrowStyle;
            max-width: 1.5em;
            right: 1em;
          }
        }
      }
    }
  }

  .logo_wrapper {
    z-index: 9;
    .logo_img {
      left: 0;
    }
    .logo {
      width: 100%;
      // margin-left: 1.5em;
      max-width: 16.2em;
      object-fit: contain;
    }
  }

  .hamburger_menu {
    position: fixed;
    top: 0;
    left: -100%;
    background: transparent;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition: 0.3s left;
    &.active {
      left: 0;
    }
    .logo_wrapper {
      position: absolute;
      max-height: 10vh;
      top: 0;
      margin: auto;
      left: 0;
      z-index: 9;
      .logo {
        width: 100%;
        margin-left: 1.5em;
        max-width: 16.2em;
        padding: 2em 0;
      }
    }
    .content_box,
    .blank_space {
      width: 100%;
      height: 100%;
    }
    // .hamburger_space {
    //   background: rgba($color: $color-181818, $alpha: 0.25);
    // }
  }

  .hamburger_links {
    position: relative;
    background: rgba($color: $color-ffffff, $alpha: 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 10.1em 1.5em 0 15em;
    z-index: 5;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: $color-212529;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-212529;
    }
    .nav_item {
      text-align: left;
      width: 100%;
      position: relative;
      cursor: pointer;
      margin-left: 0;
    }
    .nav_link_wrapper {
      position: relative;
      width: fit-content;
      margin: 0 auto;
    }
    .nav_link {
      cursor: pointer;
      color: $color-212529;
      // text-transform: capitalize;
      font-size: 1.75em;
      font-weight: 500;
      padding: 0.5em 0;
      opacity: 0.9;
      display: block;
      text-align: left;
      border: none;
      text-decoration: none;
      .dashed_line {
        display: none;
      }
    }
    .nav_link.active {
      font-size: 2.625em;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 700;
      color: $color-212529;
      border: none;
      margin: 0 0 0 -1.25em;
      transition: all 0.3s;
      .active_arrow {
        display: block;
        max-width: 2em;
        object-fit: contain;
      }
      .dashed_line {
        width: 100%;
        max-width: 2em;
        display: block;
        margin: 0 0.25em 0 0;
        max-height: 0.25em;
      }
    }

    .mobile_cta {
      display: none;
    }
  }

  .hamburger_lines {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // right: 3.5em;
    margin-left: 2.3125em;
    width: 2.8em;
    height: 1.75em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
    .line {
      height: 2px;
      width: 100%;
      border-radius: 1em;
      background-color: $color-ffffff;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    .line1 {
      max-width: 100%;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    .line2 {
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    .line3 {
      max-width: 100%;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    &.active {
      .line {
        background-color: $color-ffffff;
      }
      .line1 {
        max-width: 100%;
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: translateY(0.75em) rotate(135deg);
      }

      .line2 {
        max-width: 100%;
        opacity: 0;
        left: -60px;
      }

      .line3 {
        max-width: 100%;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: translateY(-0.85em) rotate(-135deg);
      }
    }
    &.black {
      .line {
        background-color: $color-212529;
      }
    }
  }
}

// @media (max-width: 1040px) {
//   .header_sec {
//     .hamburger_lines {
//       right: 1.5em;
//     }
//   }
// }

@media (max-width: 992px) {
  .header_sec.active {
    background: $color-ffffff;
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
  }
  .header_sec {
    .header {
      .navlinks {
        ul {
          float: none;
          justify-content: flex-start;
          max-width: 100%;
          .logo_wrapper {
            position: relative;
            top: auto;
            bottom: auto;
            margin: 0;
            left: auto;
          }
          .nav_tab {
            display: none;
          }
        }
      }
    }
    .hamburger_lines {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5em;
    }
    .hamburger_lines.active {
      .line {
        background-color: $color-181818;
      }
    }
    .hamburger_menu {
      .mobile_menu {
        height: 100%;
      }
    }
    .hamburger_links {
      padding: 10.1em 1.5em 0 7.25em;
      .nav_link {
        font-size: 2.4em;
      }
      .nav_link.active {
        font-size: 3.2em;
      }
      .mobile_cta {
        display: block;
      }
    }
  }
}

@media (max-width: 600px) {
  .header_sec {
    padding: 2.1em 0 1.5em;
    .hamburger_links {
      padding: 9em 1.5em 0 7.25em;
      .nav_link {
        font-size: 1.8em;
      }
      .nav_link.active {
        font-size: 3em;
        line-height: 1em;
      }
      .mobile_cta {
        .common_cta {
          margin: 0.65em 0 0;
        }
      }
    }
  }
}
