@import "../../common.scss";

.whyupgrad_banner_sec {
}
.whyupgrad_sec1 {
  position: relative;
  padding: 6.875em 0 0;
  .section_title {
    margin-bottom: 0.8572em;
  }
  .section_description {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.whyupgrad_sec2 {
  padding: 7.5em 0 0;
  .box_layout_01 {
    .box_text {
      .box_title {
        font-weight: 700;
      }
    }
  }
}

.whyupgrad_sec3 {
  padding: 7.5em 0 0;
  .box_layout_01 {
    .box_text {
      .box_title {
        font-weight: 700;
      }
    }
  }
}

.whyupgrad_sec4 {
  padding: 7.5em 0 0;
  .box_layout_01 {
    .box_text {
      .box_title {
        font-weight: 700;
      }
    }
  }
}

.whyupgrad_sec5 {
  padding: 7.5em 0 0;
  .box_layout_01 {
    .box_text {
      .box_title {
        font-weight: 700;
      }
    }
  }
}

.whyupgrad_sec6 {
  padding: 7.46875em 0 7.53125em;
  .content_wrapper {
    padding-right: 10em;
  }
  .vert_horizon_line {
    &::before {
      // top: -4em;
      // height: 12em;
      top: -67%;
      height: 200%;
    }
    &::after {
      top: 15%;
    }
  }
}

@media (max-width: 991px) {
  .whyupgrad_sec6 {
    .content_wrapper {
      padding-right: 0;
    }
  }
}

@media (max-width: 992px) {
  .whyupgrad_sec6 {
    .vert_horizon_line {
      &::before {
        top: -2em;
        height: 11em;
      }
    }
  }
}

@media (max-width: 991px) {
  .whyupgrad_sec1 {
    padding: 3.957em 0.1em 0 2.05em;
  }

  .whyupgrad_sec2 {
    padding: 6em 0 0;
  }
  .whyupgrad_sec3 {
    padding: 6em 0 0;
  }
  .whyupgrad_sec4 {
    padding: 6em 0 0;
  }
  .whyupgrad_sec5 {
    padding: 6em 0 0;
  }

  .whyupgrad_sec6 {
    padding: 6em 0.1em 6em 2.1em;
  }
}

@media (max-width: 600px) {
  .whyupgrad_sec6 {
    .vert_horizon_line {
      &::before {
        height: 8.75em;
      }
    }
  }
}
