@import "../../common.scss";

.terms_sec1 {
  padding: 5em 0 0;
  .text_container {
    margin-bottom: 7.5em;
    .description {
      @include textStyles(1.75em, 1.5, 0);
      color: $color-333333;
      font-family: "Montserrat", sans-serif;
      max-width: 78%;
      text-align: center;
      margin: 1.4286em auto 0;
    }
  }
  .terms_tabs_wrapper {
    display: none;
    // display: flex;
    justify-content: center;
    scroll-behavior: smooth;
    flex-wrap: wrap;
    .terms_tab {
      @include textStyles(1.875em, normal, 0);
      color: $color-212529;
      margin: 0 1.25em 1em 0;
      font-weight: 400;
      cursor: pointer;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -0.2em;
        width: 0;
        height: 3px;
        background-color: $color-EE4E56;
        transition: 0.3s width;
      }
    }
    .terms_tab.active {
      color: $color-EE4E56;
      font-weight: bold;
      &::after {
        width: 100%;
      }
    }
  }
  .text_container2 {
    .heading {
      @include textStyles(4em, 1.3125, 0);
      color: $color-333333;
      font-weight: 400;
      font-family: "Raleway";
      max-width: 100%;
      text-align: left;
    }
    .description {
      @include textStyles(1.75em, 1.5, 0);
      color: $color-333333;
      font-family: "Montserrat", sans-serif;
      max-width: 100%;
      margin-top: 1.4286em;
      text-align: left;
    }
    ul {
      li {
        position: relative;
        // padding-left: 1.5em;
        margin-left: 0.85em;
        margin-bottom: 1em;
        list-style: disc;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0.75em;
        //   left: 0.1em;
        //   width: 0.5em;
        //   height: 0.5em;
        //   border-radius: 50%;
        //   background-color: $color-EE4E56;
        // }
      }
    }
  }
}

@media (max-width: 600px) {
  .terms_sec1 {
    padding: 5em 0;
    .text_container {
      .description {
        font-size: 1.8em;
        line-height: 1.667;
        margin: 1.223em 0 auto;
      }
    }
    .text_container2 {
      .heading {
        font-size: 4em;
        line-height: 1.3;
      }
      .description {
        font-size: 1.8em;
        line-height: 1.667;
        margin-top: 1.223em;
      }
    }
  }
}
