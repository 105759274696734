@import "../../common.scss";

.am_card {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  margin: 0 0 2.188em;
  border: 1px solid #1818181f;
  overflow: hidden;
  color: $color-181818;
  &:hover {
    color: $color-181818;
    .am_img {
      transform: scale(1.2);
    }
  }
  .image_wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .am_img {
      position: relative;
      width: 100%;
      z-index: -1;
      transition: all 0.3s ease-in-out;
    }
  }
  .am_text {
    position: relative;
    background: $color-ffffff;
    padding: 2em 1.5em 2.188em 2.25em;
    z-index: 1;
    .am_title {
      @include textStyles(2em, normal, 0);
      font-weight: 700;
      font-family: $RL;
    }
    .am_desc {
      @include textStyles(1.375em, 1.545em, 0);
      font-family: $MS;
      margin: 1.273em 0 0;
    }
  }
}

@media (max-width: 992px) {
  .am_card {
    .am_text {
      padding: 1.5em 1.5em 2.5em;
      .am_title {
        font-size: 2.2em;
      }
      .am_desc {
        font-size: 1.6em;
      }
    }
  }
}
@media (max-width: 600px) {
  .am_card {
    margin: 0 0 3em;
    .am_text {
      padding: 2em 1.4em 4.5em 2.1em;
      .am_title {
        font-size: 2.2em;
      }
      .am_desc {
        font-size: 1.8em;
      }
    }
  }
}
