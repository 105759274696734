@import "../../common.scss";

.accom_sec1 {
  position: relative;
  padding: 6.875em 0 0;
  .section_title {
    margin-bottom: 0.8572em;
  }
  .section_description {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.accom_sec2 {
  padding: 5.25em 0 0;
  .box_layout_01 {
    .left_box {
      padding: 3.75em 14.5em 3.75em 6.1875em;
    }
    .right_box {
      padding: 3.75em 9.75em 3.75em 5em;
    }
    .acc_title {
      @include textStyles(2em, 1.75em, 0);
      font-family: $RL;
      font-weight: 700;
    }
    .highlights_list {
      padding: 2.125em 0 0;
      .list_item {
        @include textStyles(1.5em, 1.417em, 0);
        display: flex;
        align-items: flex-start;
        margin: 0 0 1.25em;
        .icon {
          width: 100%;
          max-width: fit-content;
          max-width: 1.21em;
          object-fit: contain;
          margin: 0 0.938em 0 0;
        }
      }
    }
    .acc_desc {
      @include textStyles(1.5em, 1.417em, 0);
      font-weight: 500;
    }
    .cta_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 2.125em 0 0;
      .common_cta {
        margin: 0 1.125em 0 0;
      }
    }
  }
  .accomm_row {
    margin-bottom: 7.5em;
  }
}

.accom_sec3 {
  .content_wrapper {
    padding-right: 10em;
  }
}

.accom_sec4 {
  padding: 8.09375em 0 6.9375em;
  .restrooms_row {
    margin: 0 auto;
  }
  .restrooms_col {
    padding: 0;
  }
  .image_wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    .rest_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }

  .conent_wrapper {
    padding: 0 3.375em 0 4.9375em;
    .heading {
      color: #181818;
      font-size: 4em;
      font-family: "Raleway";
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.3125;
      margin-bottom: 0.625em;
    }
    .section_description {
      color: #181818;
      margin-bottom: 1.42858em;
    }
    .restroom_lists_wrapper {
      li {
        color: #181818;
        font-size: 1.75em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.5;
        margin-bottom: 0.78572em;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .rest_list_img {
        width: 100%;
        // max-width: fit-content;
        max-width: 0.786em;
        object-fit: contain;
        // margin: 0.2em 0.5em 0 0;
        margin: 0.4em 0.5em 0 0;
      }
      .daily_img {
        max-width: 0.5359em;
      }
      .separate_img {
        max-width: 0.8em;
        margin: 0.4em 0.4em 0 0;
      }
    }
    .cta_wrapper {
      margin-top: 2.5em;
    }
  }
}

@media (max-width: 991px) {
  .accom_sec2 {
    .accomm_row {
      margin-bottom: 0;
    }
  }
  .accom_sec3 {
    .content_wrapper {
      padding-right: 0;
    }
  }
  .accom_sec4 {
    .conent_wrapper {
      padding: 3.375em 3.375em 0 4.9375em;
    }
  }
}

@media (max-width: 767px) {
  .accom_sec1 {
    padding: 3.957em 0.1em 0 2.1em;
  }

  .accom_sec2 {
    padding: 6em 0 0;
    .accomm_row {
      margin: 0 auto;
    }
    .box_layout_01 {
      //   .box_img {
      //     min-height: 36em;
      //   }
      .left_box {
        padding: 3em 1.6em 6em;
      }
      .right_box {
        padding: 3em 1.6em 6em;
      }
      .acc_title {
        font-size: 2.2em;
      }
      .highlights_list {
        padding: 2.5em 0 0;
        .list_item {
          font-size: 1.8em;
          margin: 0 0 1em;
          .icon {
            max-width: 1.167em;
          }
        }
      }
      .acc_desc {
        font-size: 1.8em;
        padding: 0.222em 0 0;
      }
      .cta_wrapper {
        margin: 2.2em 0 0;
        .common_cta {
          margin: 0 0.5em 0 0;
        }
      }
    }
  }

  .accom_sec3 {
    .content_wrapper {
      padding: 0 0 0 2.4em;
    }
  }

  .accom_sec4 {
    padding: 6em 0 7em;
    .conent_wrapper {
      padding: 3em 1.6em 0 1.6em;
      .section_description {
        margin-bottom: 1.667em;
      }
      .heading {
        font-size: 4em;
        line-height: 1.325;
        margin-bottom: 0.55em;
      }
      .restroom_lists_wrapper {
        li {
          font-size: 1.8em;
          line-height: 1.667;
          margin-bottom: 1em;
        }
        .rest_list_img {
          max-width: 1.2225em;
          margin: 0.25em 0.556em 0 0;
        }
        .daily_img {
          max-width: 0.8em;
          margin: 0.25em 0.566em 0 0.5em;
        }
      }
    }
  }
}
